<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">考试管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">线上考试管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">试卷列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="考试名称" class="searchboxItem ci-full">
              <span class="itemLabel">考试名称:{{ lastPageData.examinationName }}</span>
            </div>
            <div title="考试科目" class="searchboxItem ci-full">
              <span class="itemLabel">考试科目:{{ $setDictionary("EA_EXAM_SUBJECT_CODE", lastPageData.subjectCode) }}</span>
            </div>
            <template  v-if="!['-1','1'].includes(userJson.roleId)">
              <el-button type="primary" class="bgc-bv" round @click="selectPapers">选择试卷</el-button>
              <el-button type="primary" class="bgc-bv" round @click="$router.back()">返回</el-button>
            </template>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column label="试卷名称" align="left" show-overflow-tooltip prop="paperName" minWidth="150" />
              <el-table-column label="试卷类型" align="left" show-overflow-tooltip prop="examType" minWidth="100">
                <template slot-scope="scope">
                  {{ $setDictionary("TOOL_EXAM_TYPE", scope.row.examType) }}
                </template>
              </el-table-column>
              <el-table-column label="试题数量" align="left" show-overflow-tooltip prop="questionNum" minWidth="100" />
              <el-table-column label="试题总分" align="left" show-overflow-tooltip prop="score" minWidth="100" />
              <el-table-column label="创建时间" align="left" show-overflow-tooltip min-width="150px">
                <template slot-scope="scope">
                  {{ scope.row.createTime | moment }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="100px">
                <template slot-scope="scope">
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="previewExaminationPaper(scope.row)">预览</el-button>
                  <el-button type="text" style="padding: 0px 5px" size="mini"
                    @click="deleteExaminationPaper(scope.row)" v-if="lastPageData.noDelete != '20'">删除</el-button>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "examination_onlineTest_examinationPaperList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        examinationId: "", // 考试id
        examinationName: "", // 考试名称
        subjectCode: "", // 考试科目
        noDelete:"", // 是否显示删除按钮:10或空-显示;20-不显示
      },
    };
  },
  created() {
    this.lastPageData.examinationId = this.$route.query.examinationId;
    this.lastPageData.examinationName = this.$route.query.examinationName;
    this.lastPageData.subjectCode = this.$route.query.subjectCode;
    this.lastPageData.noDelete = this.$route.query.noDelete;
    this.dataDictionary();
  },
  watch: {},
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  mounted() { },
  methods: {
    // 获取 - 职业名称
    getOccupation(occupationName) {
      if (occupationName.trim().length >= 2) {
        this.$post("/biz/examination_affairs/activity/selectCtOccupationList", { occupationName }, 3000, true, 6)
          .then((ret) => {
            this.occupation = ret.data
          })
          .catch((err) => {
            return;
          });
      } else {
        this.occupation = [];
      }
    },
    // 获取 - 数据字典
    dataDictionary() {
      // 考试科目
      const subjectCodeList = this.$setDictionary(
        "EA_EXAM_SUBJECT_CODE",
        "list"
      );
      const list1 = [];
      for (const key in subjectCodeList) {
        list1.push({
          value: key,
          label: subjectCodeList[key],
        });
      }
      this.subjectCodeList = list1;
    },
    // 获取 - 列表数据
    getData(pageNum = 1) {
      const params = {
        examinationId: this.$route.query.examinationId,
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      this.doFetch({
        url: "/biz/examination/paper/pageList",
        params,
        pageNum,
      }, true, 6);
    },
    // 选择试卷
    selectPapers() {
      this.$router.push({
        path: "/web/examination/onlineTest/queryExaminationPaperList",
        query: {
          examinationId: this.lastPageData.examinationId, // 考试id
          score: this.tableData[0]?.score || '' // 试题总分
        }
      });
    },
    // 预览
    previewExaminationPaper(row) {
      this.$router.push({
        path: "/inkQuestionBank/examinationPaperManage/tacticsUpdate",
        query: {
          paperId: row.paperId,
          stu: 'view'
        }
      });
    },
    // 删除
    deleteExaminationPaper(row) {
      this.doDel({
        url: "/biz/examination/paper/deletePaper",
        msg: "如考试已经开始，替换试卷将影响已经答卷的考生，请删除后通知学员重新进入考试，确认继续删除吗？",
        ps: {
          type: "post",
          data: {
            examinationId: this.lastPageData.examinationId,
            examinationPaperIds: [row.paperId]
          },
        },
      }, true, 6);
    }
  }
};
</script>
<style lang="less" scoped></style>